import React, { Component } from 'react'
import Modal from 'react-modal'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { MdClose } from 'react-icons/md'
import { withModalContext } from 'src/context/ModalContext'

import Video from 'src/components/Video'

import Button from 'src/components/Button'
import Grid from 'src/components/Grid'

import { colors, util, mq } from 'src/styles'

const Wrapper = styled.div``

Modal.setAppElement('#___gatsby')

const CloseButton = styled(Button)`
  position: absolute;
  top: -20px;
  right: -50px;
  color: ${ colors.textColor };
  opacity: .3;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  &:hover {
    color: ${ colors.textColor };
    background: transparent;
    border-color: transparent;
    opacity: 1;
  }
`

const InnerWrapper = styled.div`
  width: 100%;
  text-align: center;

  // ${ util.responsiveStyles('padding', 75, 50, 40, 20) }
`

const styles = {
  background: 'red',
  content: {
    position: 'relative',
    background: colors.bgColor,
    maxWidth: '1000px',
    width: '100%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    top: 'auto',
    left: 'auto',
    borderRadius: '0',
    border: '14px solid white',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflow: 'visible'
  },
  overlay: {
    padding: '7%',
    overflow: 'auto',
    background: rgba(colors.lightGrey, .8),
    zIndex: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}

class VideoModal extends Component {

  render() {
    const { modalContext } = this.props
    const { modalIsOpen, closeModal, modalData } = modalContext
    // const { title, buttonLabel } = modalData

    return (
      <Modal
        style={styles}
        isOpen={modalIsOpen}
        closeTimeoutMS={500}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => closeModal()} 
      >
        <Grid
          small="[12]"
          medium={"[1]"}
          vAlign="center"
        >
          <InnerWrapper>
            <Video 
              video={modalData}
              muted={false}
              playButton={false}
              controls={true}
            />
            <CloseButton onClick={closeModal} shape="circle" setTheme="transparent" size="tiny"><MdClose size={24}/></CloseButton>
          </InnerWrapper>
        </Grid>
      </Modal>
    );
  }
}


export default withModalContext(VideoModal)