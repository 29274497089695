import React, { useContext, Fragment } from 'react'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player/file'
import { MdPlayArrow } from 'react-icons/md'
import { ModalContext, withModalContext } from 'src/context/ModalContext'

import Button from 'src/components/Button'

import { colors, util, mq, animations, typography } from 'src/styles'

const Wrapper = styled.div`
	video {
		display: block;
		margin: 0;
	}
	position: relative;
`

const VideoWrapper = styled.div`
	${ ({ cover }) => cover && `
		height: 100%;
	` }
`

const StyledVideo = styled(ReactPlayer)`
	z-index: 10;
	position: relative;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	width: 100% !important;
	height: auto !important;
	> div {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	${ ({ cover }) => cover && `
		video {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	` }
	${ ({ url, cover }) => url.indexOf('youtube') > -1 || url.indexOf('vimeo') > -1
? `
		padding-bottom: ${ 9 / 16 * 100 }%;
		${ cover && `
			position: absolute;
			top: 50%;
			left: 50%;
			padding: 0;
			width: 100% !important;
			height: 100% !important;
			@media (min-aspect-ratio: 16/9) {
				// tall
				min-width: 100vw;
				min-height: 56.25vw;
				margin-left: -50vw;
				margin-top: -28.125vw;
			}
			@media (max-aspect-ratio: 16/9) {
				// wide
				min-width: 177.77vh;
				min-height: 100vh;
				margin-left: -88.885vh;
				margin-top: -50vh;
			}
			min-width: 0;
			min-height: 0;
		` }
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	`
: `
	` }
`



const PlayButtonWrap = styled.div`
	position: absolute;
  bottom: 14px;
  left: 14px;
  z-index: 12;
`

const PlayText = styled.span`
	${ typography.h6 }
	display: inline-block;
	vertical-align: middle;
	padding: 0 0 0 13px;
	color: ${ colors.white };
	transform: translate3d(0, 14px, 0);
	opacity: 0;
	text-shadow: 0 0 6px 12px rgba(0, 0, 0, .3);
	transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
`

const PlayButton = styled(Button)`
  
  color: ${ colors.white };
  opacity: 1;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  svg {
  	transition: transform ${ animations.mediumSpeed } ease-in-out;
  }
  &:hover {
    color: ${ colors.white };
    background: ${ colors.mainColor };
    border-color: transparent;
    opacity: 1;
    svg {
    	transform: scale(0.7);
    }
  }
`

const Overlay = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(to top,rgba(0,0,0,.25) 0%,rgba(0,0,0,0) 50%);
  z-index: 11;
  &:hover {
  	cursor: pointer;
  	> ${PlayButtonWrap} {

  		> ${PlayText} {
	      opacity: 1;
	      transform: none;
  		}
    }
  }

`


const Video = ({
  video,
	loop,
	cover,
	muted,
	autoplay,
	className,
	playing,
	src,
	playButton,
	controls
}) => {
	if (!src && !video && !video.file && !video.file.url) {
		return false
	}
	const modalContext = useContext(ModalContext)
	console.log('video', playButton)
	return (
		<Wrapper className={className} cover={cover}>
			<VideoWrapper cover={cover} playButton={playButton}>
				<StyledVideo
					cover={cover}
					url={src || video.file.url}
					playing={playing}
					loop={loop}
					muted={muted}
					autoPlay={autoplay}
					controls={controls}
				/>
				{ playButton ? (
					<Overlay onClick={(e) =>	{modalContext.openModal(video)}} >
					<PlayButtonWrap>
						<PlayButton shape="circle" setTheme="transparent" size="small"><MdPlayArrow size={32}/></PlayButton>
						<PlayText>Play video</PlayText>
						</PlayButtonWrap>
					</Overlay>
				) : false}
			</VideoWrapper>
		</Wrapper>
	)
}

Video.defaultProps = {
	playing: true,
	loop: true,
	muted: true,
	autoplay: true,
	controls: false
}

export default withModalContext(Video)