import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import { rgba, darken } from 'polished'
import Image from 'src/components/Image'
import Button from 'src/components/Button'

import { colors, mediaQueries as mq, animations, util } from 'src/styles'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'

const thumbnailGutter = 10

// const StyledSlider = styled(Slider)`
// * {transition: height ${ animations.mediumSpeed } ease-in-out;}
// 	.slick-slide > div {
// 		display: flex;
// 		justify-content: center;
// 	}
// `

const SlideshowWrapper = styled.div`
	max-width: 100%;
	overflow: hidden;
`

const ThumbnailsWrapper = styled.div`
	${ ({ itemCount }) => itemCount < 5 ? `
		width: ${ itemCount * 20 }%;
		margin: 0 auto;
	` : `` }
	.slick-slider {
	}
	.slick-list {
		margin: 0 ${ (thumbnailGutter - 4) * -.5 }px 0 ${ thumbnailGutter * -.5 }px;
	}
	.slick-slide {
		padding: ${ thumbnailGutter }px ${ thumbnailGutter * .5 }px 0;
		box-sizing: border-box;
		position: relative;
		cursor: pointer;
		outline: none;
		&:hover {
			&:after {
				border-color: ${ colors.mainColor };
			}
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: ${ thumbnailGutter }px;
			left: ${ thumbnailGutter * .5 }px;
			right: ${ thumbnailGutter * .5 }px;
			bottom: 0;
			border: 2px solid ${ colors.hrColor };
			transition: border ${ animations.mediumSpeed } ease-in-out;

		}
		&.slick-current {
			&:after {
				border-color: ${ colors.textColor };
			}
		}
	}
`

const Slide = styled.div`
	width: 100%;
`

const NextPrevButton = styled(Button)`
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin: 0;
	z-index: 3;
	min-height: 32px;
	min-width: 32px;
	${ ({ position, dots }) => position === 'left' ? `
		left: 20px;
		${ dots ? util.responsiveStyles('margin-top', -25, -20, -18, -17) : `` }
	` : `
		right: 20px;
		${ dots ? util.responsiveStyles('margin-top', -25, -20, -18, -17) : `` }
	`}
	${ mq.smallAndBelow } {
		display: none;
	}
`

const SlideshowDots = styled.ul`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	list-style: none;
	li {
		transition: color ${ animations.mediumSpeed } ease-in-out;
		cursor: pointer;
		color: ${ darken(.1, colors.lightGrey) };
		&:hover {
			color: ${ colors.textColor };
		}
		&.slick-active {
			color: ${ colors.textColor };
			opacity: 1;
			pointer-events: none;
		}
	}
`

const SlideshowDot = styled.div`
	padding-left: 12px;
	padding-right: 12px;
	${ util.responsiveStyles('height', 50, 40, 36, 34) }
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		display: block;
		width: 8px;
		height: 8px;
		background: currentcolor;
		border-radius: 50%;
	}
`

class Slideshow extends Component {
	constructor(props) {
    super(props);
    this.state = {
      slideshow: null,
      thumbnailSlideshow: null
    };
  }

	goToNextSlide = () => {
		if (this.slideshow) {
			this.slideshow.slickNext()
		}
	}

	goToPrevSlide = () => {
		if (this.slideshow) {
			this.slideshow.slickPrev()
		}
	}

	componentDidMount () {
    this.setState({
      mainSlideshow: this.slideshow,
      thumbnailSlideshow: this.props.thumbnails ? this.thumbnailSlideshow : null
    })
  }

	render () {
		const { mainSlideshow, thumbnailSlideshow } = this.state

		const {
			children,
			slides,
			className,
			speed = 750,
			fade = false,
			autoplay = false,
			settings,
			thumbnailSettings,
			thumbnails,
			nextIcon,
			prevIcon,
			controls = true,
			dots = true,
			pauseOnHover = true,
			autoplaySpeed = 7000
		} = this.props

		if (!slides && !children) {
			return false
		}

		if (!slides) {
			let slides = children
		}

		const defaultSettings = {
			dots: dots,
			arrows: false,
			infinite: true,
			speed: speed,
			fade: fade,
			autoplay: autoplay,
			autoplaySpeed: autoplaySpeed,
			pauseOnHover: pauseOnHover,
			centerPadding: 50,
			adaptiveHeight: true,
			appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
	    customPaging: i => (
	      <SlideshowDot><span/></SlideshowDot>
	    )
		}

		const defaultThumbnailSettings =  {
			slidesToShow: slides.length <= 5 ? slides.length : 5,
		 	slidesToScroll: 1,
		 	speed: speed,
		 	autoplay: autoplay,
		 	autoplaySpeed: 7000,
		 	dots: false,
		 	arrows: false,
		 	focusOnSelect: true
		}

		const setSlideshowSettings = defaultSettings || settings
		const setThumbnailSettings = defaultThumbnailSettings || thumbnailSettings

		return (
			<Fragment>
				<div style={{ position: 'relative' }} className={className}>
					<SlideshowWrapper>
						{slides.length > 1 ? (
							<Slider
								ref={slider => (this.slideshow = slider)}
								asNavFor={thumbnailSlideshow}
								{...setSlideshowSettings}
							>
								{slides && slides.map((image, index) => (
									<Image
										key={index}
										image={image}
										loading={index === 0 ? "lazy" : "eager"}
									/>
								))}
							</Slider>
						) : (
							<Slide>
								<Image image={slides[0]} />
							</Slide>
						)}
					</SlideshowWrapper>
					{slides.length > 1 && controls && (
						<Fragment>
							<NextPrevButton dots={dots} shape="square" className="prev-button" size="small" setTheme="forestGreen" onClick={this.goToPrevSlide} position="left">{prevIcon ? prevIcon : <MdKeyboardArrowLeft size={32}/>}</NextPrevButton>
							<NextPrevButton dots={dots} shape="square" className="next-button" size="small" setTheme="forestGreen" onClick={this.goToNextSlide} position="right">{nextIcon ? nextIcon : <MdKeyboardArrowRight size={32}/>}</NextPrevButton>
						</Fragment>
					)}
				</div>

				{thumbnails && (
					<ThumbnailsWrapper itemCount={slides.length}>
						{slides.length > 1 ? (
							<Slider
								ref={slider => (this.thumbnailSlideshow = slider)}
								asNavFor={mainSlideshow}
								{...setThumbnailSettings}
							>
								{slides && slides.map((item, index) => (
									<Slide key={index}>{item}</Slide>
								))}
							</Slider>
						) : (
							<Slide>{slides}</Slide>
						)}
					</ThumbnailsWrapper>
				)}
			</Fragment>
		)
	}
}

export default Slideshow
