import React from "react"
import styled from "@emotion/styled"
import { typography, colors, mq } from "src/styles"
import ScrollEntrance from "src/components/ScrollEntrance"
import ContentfulRichText from "src/components/ContentfulRichText"

const setQuoteTheme = {
  default: {
    lineColor: colors.hrColor,
    quoteColor: colors.blue,
  },
  dark: {
    lineColor: "rgba(255, 255, 255, .1)",
    quoteColor: colors.blue,
  },
  lightGrey: {
    lineColor: colors.hrColor,
    quoteColor: colors.blue,
  },
  blue: {
    lineColor: "rgba(255, 255, 255, .1)",
    quoteColor: colors.white,
  },
  mainColor: {
    lineColor: "rgba(255, 255, 255, .1)",
    quoteColor: colors.white,
  },
}

const Wrapper = styled(ScrollEntrance)``

const QuoteWrapper = styled.div`
  border-left: 4px solid;
  ${({ colorTheme }) => `
    border-color: ${setQuoteTheme[colorTheme].lineColor};
  `}
  padding-top: 1em;
  p {
    padding-left: 1em;
  }
  p:first-of-type:before {
    content: "\u201C";
    display: block;
    font-size: 4em;
    ${({ colorTheme }) => `
      color: ${setQuoteTheme[colorTheme].quoteColor};
    `}
  }
`

const Text = styled.div`
  p {
    ${({ textSize }) => typography[textSize]}
    margin-bottom: 0;
    &:first-of-type {
      margin-top: 0;
    }
    font-weight: ${typography.bold};
  }
`
const AuthorWrap = styled.div`
 
  margin-top: 1.75rem;
  p {
    ${({ textSize }) => typography[textSize]}
    font-weight: ${typography.bold};
    margin-bottom: 0;
  }
`

const AuthorByline = styled.p`
  font-weight: ${typography.normal};
  margin-top: 0;
  margin-bottom: 0;
  &:last-of-type {
    margin-bottom: 1.75em;
  }
`

// const Author = styled.div``

const BlockQuote = ({
  className,
  quote,
  theme,
  textSize,
  authorName,
  authorTitle,
  authorOrganization,
  children
}) => (
  <Wrapper className={className} textSize={textSize}>
    {quote && (
      <QuoteWrapper colorTheme={theme || "default"}>
        <Text textSize={textSize}>
          {quote.json ? (
            <ContentfulRichText transitionIn={true} richText={quote.json} />
          ) : quote}
        </Text>
      </QuoteWrapper>
    )}

    {authorName && (
      <AuthorWrap textSize={textSize}>
        <p>&mdash;{authorName}</p>
      </AuthorWrap>
    )}

    {authorTitle && <AuthorByline>{authorTitle}</AuthorByline>}

    {authorOrganization && <AuthorByline>{authorOrganization}</AuthorByline>}
  </Wrapper>
)

export default BlockQuote
