import React, { Component, Fragment } from "react"
import { rgba } from "polished"
import styled from "@emotion/styled"
import Slider from "react-slick"
import Image from "src/components/Image"
import Button from "src/components/Button"
import ContentfulRichText from "src/components/ContentfulRichText"

import {
  colors,
  mediaQueries as mq,
  animations,
  util,
  typography,
} from "src/styles"
import { MdArrowBack, MdArrowForward } from "react-icons/md"

const CustomSlider = styled(Slider)`
  ${mq.largeAndUp} {
    ${util.responsiveStyles("margin-right", -800, -500, -260, -300)}
  }
`

const SlideshowWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  ${util.responsiveStyles("padding-bottom", 170, 100, 60, 48)}
  ${mq.largeAndUp} {
    padding-left: ${100 / 14 + "vw"};
  }
`

const Slide = styled.div`
  width: 100%;
  position: relative;
  padding-left: ${100 / 14 + "vw"};
  padding-right: ${100 / 14 + "vw"};
  ${mq.largeAndUp} {
    ${util.responsiveStyles("padding-right", 30, 24, 20, 14)}
    padding-left: 0;
  }
`

const SlideImage = styled(Image)`
  position: absolute;
  z-index: 1;
`

const SlideText = styled.div`
  margin-top: 5vw;
  align-items: stretch;
  display: flex;
  ${mq.largeAndUp} {
    background: linear-gradient(
      0deg,
      ${rgba(colors.extraDarkBlue, 0.8)} 0%,
      ${rgba(colors.extraDarkBlue, 0)} 100%
    );
    margin-top: 0;
  }
  h2,
  h3,
  h4,
  h5 {
    font-size: ${typography.h3};
    ${util.responsiveStyles("font-size", 42, 36, 24, 24)}
    margin-bottom: 0;
  }
  h1 {
    ${util.responsiveStyles("font-size", 100, 90, 60, 50)}
    display: inline-block;
    margin: 0 0.25em 0 0;
    padding-right: 0.25em;
    line-height: 0.95;
    border-right: 2px solid ${colors.mainColor};
    ${mq.largeAndUp} {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  p {
    max-width: 30rem;
    margin-top: 0.75em;
    ${mq.largeAndBelow} {
      ${typography.bodySmall}
    }
    ${mq.mediumAndBelow} {
      ${typography.body}
    }
  }
  ${mq.largeAndUp} {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    ${util.responsiveStyles("padding", 50, 30, 20, 16)}
    ${util.responsiveStyles("padding-top", 80, 60, 50, 30)}
  }
`

const NextPrevButton = styled(Button)`
  cursor: pointer;
  position: relative;
  // top: 50%;
  // transform: translateY(-50%);
  margin: 0;
  z-index: 3;
  min-height: 32px;
  min-width: 32px;
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  ${({ position }) =>
    position === "right"
      ? `
    ${util.responsiveStyles("margin-left", 20, 16, 10, 10)}
  `
      : ``}
  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: ${colors.white};
  }
`

const NavButtonsWrap = styled.div`
  position: absolute;
  display: flex;
  ${util.responsiveStyles("bottom", 170, 100, 60, 48)}
  z-index: 3;
  margin-right: -10px;
  right: ${100 / 14 + "vw"};
`

const SlideshowDots = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  margin-left: -12px;
  ${util.responsiveStyles("margin-top", 50, 34, 28, 20)}
  list-style: none;
  ${mq.mediumAndBelow} {
    padding-left: ${100 / 14 + "vw"};
  }
  li {
    transition: color ${animations.mediumSpeed} ease-in-out,
      transform ${animations.mediumSpeed} ease-in-out;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.4);
    transform: scale(0.85);
    &:hover {
      color: ${colors.white};
    }
    &.slick-active {
      color: ${colors.white};
      opacity: 1;
      pointer-events: none;
      transform: scale(1.15);
    }
  }
`

const SlideshowDot = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  ${util.responsiveStyles("height", 50, 40, 36, 34)}
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    width: 8px;
    height: 8px;
    background: currentcolor;
    border-radius: 50%;
  }
`

class ComplexSlideshow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slideshow: null,
      thumbnailSlideshow: null,
    }
  }

  goToNextSlide = () => {
    if (this.slideshow) {
      this.slideshow.slickNext()
    }
  }

  goToPrevSlide = () => {
    if (this.slideshow) {
      this.slideshow.slickPrev()
    }
  }

  componentDidMount() {
    this.setState({
      mainSlideshow: this.slideshow,
      thumbnailSlideshow: this.props.thumbnails
        ? this.thumbnailSlideshow
        : null,
    })
  }

  render() {
    const { mainSlideshow, thumbnailSlideshow } = this.state

    const {
      children,
      slides,
      className,
      nextIcon,
      prevIcon,
      controls = true,
      dots = true,
      type,
    } = this.props

    if (!slides && !children) {
      return false
    }

    if (!slides) {
      let slides = children
    }

    const defaultSettings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 700,
      fade: false,
      autoplay: true,
      autoplaySpeed: 7000,
      pauseOnHover: true,
      centerMode: false,
      slidesToShow: 2,
      centerPadding: 50,
      adaptiveHeight: false,
      appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
      customPaging: i => (
        <SlideshowDot>
          <span />
        </SlideshowDot>
      ),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }

    const setSlideshowSettings = defaultSettings

    return (
      <Fragment>
        <div style={{ position: "relative" }} className={className}>
          <SlideshowWrapper>
            {slides.length > 1 ? (
              <CustomSlider
                ref={slider => (this.slideshow = slider)}
                asNavFor={thumbnailSlideshow}
                {...setSlideshowSettings}
              >
                {slides &&
                  slides.map((slide, index) => (
                    <Slide first={index === 0} key={index}>
                      <SlideImage
                        key={index}
                        image={slide.image}
                        loading={index === 0 ? "lazy" : "eager"}
                      />
                      <SlideText>
                        {type === "numberedBleedOffSlideshow" ? (
                          <h1>{index + 1}</h1>
                        ) : (
                          false
                        )}
                        <ContentfulRichText
                          transitionIn={true}
                          richText={slide.text.json}
                        />
                      </SlideText>
                    </Slide>
                  ))}
              </CustomSlider>
            ) : (
              <Slide>
                <Image image={slides[0]} />
              </Slide>
            )}
          </SlideshowWrapper>
          {slides.length > 1 && controls && (
            <NavButtonsWrap>
              <NextPrevButton
                dots={dots}
                shape="circle"
                size="small"
                onClick={this.goToPrevSlide}
              >
                {prevIcon ? prevIcon : <MdArrowBack size={32} />}
              </NextPrevButton>
              <NextPrevButton
                dots={dots}
                shape="circle"
                size="small"
                onClick={this.goToNextSlide}
                position="right"
              >
                {nextIcon ? nextIcon : <MdArrowForward size={32} />}
              </NextPrevButton>
            </NavButtonsWrap>
          )}
        </div>
      </Fragment>
    )
  }
}

export default ComplexSlideshow
