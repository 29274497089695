import React, { Component } from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import Select from 'src/components/Select'
import { colors } from 'src/styles'
import { validateEmail, validatePhoneNumber } from 'src/utils/validations'

const Form = styled.form`
  display: inline-block;
  vertical-align: top;
  max-width: 600px;
`

const StyledInput = styled(Input)`
  margin-bottom: 16px;
  ${ ({ type }) => type === 'hidden' ? `
    display: none;
  ` : `` }
  ${ ({ isValid }) => isValid === false ? `
    input {
      border-color: ${ colors.red };
    }
  ` : `` }
`

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`

class ContactForm extends Component {
  constructor (props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: '',
      name: '',
      email: '',
      district: '',
      role: 'default',
      phone: ''
    }
  }

  handleInput = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render () {
    const { status, name, email, phone, role, district } = this.state
    const valid = name !== '' && email !== '' && phone !== '' && role !== 'default' && district !== '' && validateEmail(email) && validatePhoneNumber(phone)
    return (
      <Form
        onSubmit={this.submitForm}
        action="https://formspree.io/mjvpbera"
        method="POST"
      >
        <StyledInput type="hidden" name="_replyto" value={email} />
        <StyledInput type="hidden" name="_subject" value={'Interest from ' + name + ' | ' + district} />
        <StyledInput
          onChange={this.handleInput}
          type="text"
          name="name"
          label="Name"
          value={name}
        />
        <StyledInput
          onChange={this.handleInput}
          type="text"
          name="district"
          label="District"
          value={district}
        />
        <StyledSelect
          label="Role"
          name="role"
          onChange={this.handleInput}
          value={role}
          options={[
            { label: 'Chief Academic Officer', value: 'Chief Academic Officer' },
            { label: 'Dean of Instruction', value: 'Dean of Instruction' },
            { label: 'Parent', value: 'Parent' },
            { label: 'Principal', value: 'Principal' },
            { label: 'Superintendent', value: 'Superintendent' },
            { label: 'Teacher', value: 'Teacher' },
            { label: 'Other', value: 'Other' }
          ]}
        />
        <Grid small="[1]" large="[1] [1]" colGap="16px">
          <div>
            <StyledInput
              onChange={this.handleInput}
              type="tel"
              name="phone"
              label="Phone Number"
              value={phone}
              isValid={phone !== '' ? validatePhoneNumber(phone) : true}
            />
          </div>
          <div>
            <StyledInput
              onChange={this.handleInput}
              type="email"
              name="email"
              label="Email"
              value={email}
              isValid={email !== '' ? validateEmail(email) : true}
            />
          </div>
        </Grid>
        <div style={{ paddingTop: '16px' }}>
          <Button type="submit" disabled={!valid}>
            {status === 'SUCCESS' ? 'Thank You' : 'Submit'}
          </Button>
        </div>
      </Form>
    )
  }

  submitForm (ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        this.setState({ status: 'SUCCESS' })
        setTimeout(() => {
          this.setState({
            status: '',
            name: '',
            email: '',
            district: '',
            role: 'default',
            phone: ''
          })
          form.reset()
        }, 1000)
      } else {
        this.setState({ status: 'ERROR' })
      }
    }
    xhr.send(data)
  }
}

export default ContactForm
