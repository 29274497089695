import React, { Component } from 'react'
import styled from '@emotion/styled'
import { darken } from 'polished'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import Logo from 'src/components/Logo'
import Section from 'src/components/Section'
import ThemeSelector from 'src/components/ThemeSelector'
import ContentfulRichText from 'src/components/ContentfulRichText'

import { mq, util, colors, typography } from 'src/styles'

const Wrapper = styled(ThemeSelector)`
	position: relative;
	z-index: 2;
`

const FooterLogo = styled(Logo)`
	${ util.responsiveStyles('width', 130, 110, 80, 100) }
	max-width: 100%;
`

const FooterBottom = styled(ThemeSelector)`
	position: relative;
	${ util.responsiveStyles('padding-top', 40, 30, 30, 26) }
	${ util.responsiveStyles('padding-bottom', 40, 30, 30, 26) }
	background: #EEF2F4;
`

const Copyright = styled.div`
	display: flex;
	align-items: center;
	p {
		${ typography.bodySmall }
		margin: 0;
		max-width: none;
		font-weight: ${ typography.medium };
		color: ${ colors.lightTextColor };
		.mobile-hide {
			${ mq.mediumAndBelow } {
				display: none;
			}
		}
	}
`

const SiteCredit = styled.div`
	${ mq.smallAndBelow } {
		text-align: right;
	}
	p {
		${ typography.bodySmall }
		margin: 0;
		max-width: none;
		font-weight: ${ typography.medium };
		color: ${ colors.lightTextColor };
		a {
			&:hover {
				color: ${ colors.textColor };
			}
		}
	}
`

const FooterCol = styled.div`
	${ mq.largeAndUp } {
		margin-top: -4px;
	}
	p {
		${ typography.bodySmall }
		margin: 0;
		&:first-of-type {
			a {
				padding-top: 0;
			}
		}
		${ mq.smallAndBelow } {
			${ typography.body }
		}
		a {
			border-color: transparent;
			color: inherit;
			display: inline-block;
			padding: 10px 0;
			&:hover {
				color: ${ colors.blue };
			}
		}
	}
`

class Footer extends Component {
	render () {
		const { title, footerColumn1, footerColumn2 } = this.props
		return (
			<Wrapper setTheme="lightGrey">
				<Section>
					<Grid
						small="1 [12] 1"
						medium="1 [12] 1"
						large="1 [3] 1 [8] 1"
						larger="2 [12] [12] 2"
						rowGap="5vw"
					>
						<div>
							<FooterLogo />
						</div>
						<div>
							<Grid
								small="[1]"
								medium="[7] [5]"
								large="[5] [3]"
								larger="[4] [3]"
								larger="[6] 1 [5]"
								rowGap="5vw"
							>
								<FooterCol>
									<ContentfulRichText richText={footerColumn1.json} />
								</FooterCol>
								<FooterCol>
									<ContentfulRichText richText={footerColumn2.json} />
								</FooterCol>
							</Grid>
						</div>
					</Grid>
				</Section>
				<FooterBottom setTheme="lightGrey">
					<Grid
						small="1 [7] [5] 1"
						medium="1 [7] [5] 1"
						large="1 [9] [3] 1"
						larger="2 [18] 1 [5] 2"
						vAlign="center"
					>
						<Copyright>
							<p className="sm">© <span className="mobile-hide">{title}</span> {new Date().getFullYear()}</p>
						</Copyright>
						<SiteCredit><p className="sm"><Link to="https://gordilsandwillis.com/" target="_blank" external>Site Credit</Link></p></SiteCredit>
					</Grid>
				</FooterBottom>
			</Wrapper>
		)
	}
}

export default Footer
