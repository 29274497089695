import React from "react"
import styled from "@emotion/styled"
import Image from "src/components/Image"
import ContentfulRichText from "src/components/ContentfulRichText"

const setAlignment = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
  baseline: "baseline",
}

const Wrapper = styled.div`
  display: flex;
  ${({ layout }) => (layout === "vertical" ? "flex-direction: column;" : "")};
  ${({ alignment }) => `
      align-items: ${setAlignment[alignment]};
    `}
`

const ImageWrapper = styled(Image)`
  flex-grow: 0;
  display: flex;
  justify-content: center;
  img {
    margin: 0 auto;
  }
`

const TextWrapper = styled.div`
  flex-grow: 1;
  // flex-basis: 50%;
  padding-left: 1em;
`

const ImageWithText = ({
  className,
  image,
  text,
  imageWidth,
  verticalAlignment,
  layout,
}) => (
  <Wrapper className={className} alignment={verticalAlignment} layout={layout}>
    {/* <ImageWrapper> */}
    <Image image={image} maxWidth={imageWidth + "%"}></Image>
    {/* </ImageWrapper> */}
    <TextWrapper>
      {text?.json && (
      <ContentfulRichText transitionIn={true} richText={text.json} />
      )}
    </TextWrapper>
  </Wrapper>
)

export default ImageWithText
