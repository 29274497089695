import React from "react"

import TextSection from "src/components/TextSection"
import TextSectionV2 from "src/components/TextSectionV2"
import Columns from "src/components/Columns"
import WideMedia from "src/components/WideMedia"
import FiftyFifty from "src/components/FiftyFifty"
import FiftyFiftyV2 from "src/components/FiftyFiftyV2"

const componentMap = {
	ContentfulTextSection: TextSection,
	ContentfulTextSectionV2: TextSectionV2,
	ContentfulColumns: Columns,
	ContentfulFiftyFifty: FiftyFifty,
	ContentfulFiftyFiftyV2: FiftyFiftyV2,
	ContentfulWideMedia: WideMedia,
}

export default ({
	item,
	prevTheme,
	nextTheme,
	index,
	isLastSection,
	isFirstSection,
}) => {
	const Component = componentMap[item.__typename]
	return Component ? (
		<Component
			{...item}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			isLastSection={isLastSection}
			isFirstSection={isFirstSection}
			index={index}
		/>
	) : null
}
