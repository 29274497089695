import React from "react"
import styled from "@emotion/styled"
import Section from "src/components/Section"
import Grid from "src/components/Grid"
import Image from "src/components/Image"
import Video from "src/components/Video"
import ScrollEntrance from "src/components/ScrollEntrance"
import Column from "src/components/Column"
import TextLockup from "src/components/TextLockup"
import { mq } from "src/styles"

const FFSection = styled(Section)``

const ColumnWrapper = styled.div`
  ${({
    isMedia,
    vPadded,
    hPadded,
    sectionPadded,
    gutters,
    index,
    gridDirection,
  }) =>
    !isMedia
      ? `
    ${vPadded ? `` : ``}
    ${
      hPadded
        ? `
      padding-left: ${ 100 / 14 }vw;
      padding-right: ${ 100 / 14 }vw;
      ${mq.mediumAndUp} {
        padding-left: 0;
        padding-right: 0;
        ${
          index === 0
            ? `
          ${
            gridDirection === "ltr"
              ? `
            padding-left: ${ 100 / 14 }vw;
          `
              : `
            padding-right: ${ 100 / 14 }vw;
          `
          }
        `
            : ``
        }
        ${
          index === 1
            ? `
          ${
            gridDirection === "ltr"
              ? `
            padding-right: ${ 100 / 14 }vw;
          `
              : `
            padding-left: ${ 100 / 14 }vw;
          `
          }
        `
            : ``
        }
      }
    `
        : ``
    }
  `
      : `` }
`

const gridSetup = (layout, gutter) => {
  const layouts = {
    "50/50": {
      medium: '[1]',
      large: '[1] ' + gutter + ' [1]',
      larger: '[1] ' + gutter + ' [1]',
    },
    teamMember: {
      medium: '[4] ' + gutter + ' [8]',
      large: '[7] ' + gutter + ' [17]',
      larger: '[7] ' + gutter + ' [17]',
    },
    '3/1': {
      medium: '[8] ' + gutter + ' [4]',
      large: '[17] ' + gutter + ' [7]',
      larger: '[17] ' + gutter + ' [7]',
    },
    '1/3': {
      medium: '[4] ' + gutter + ' [8]',
      large: '[7] ' + gutter + ' [17]',
      larger: '[7] ' + gutter + ' [17]',
    },
  }

  return layouts[layout]
}

const gutterSetup = {
  narrow: 'g',
  wide: 'm',
  none: '',
}

const gridDirection = {
  leftToRight: 'ltr',
  rightToLeft: 'rtl',
}

const FiftyFifty = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  columns,
  gutters,
  width,
  padding,
  layout,
  verticalAlignment,
  columnOrder,
  isFirstSection,
}) => {
  // Set defaults if value is null
  if (!gutters) {
    gutters = 'narrow'
  }
  if (!width) {
    width = 'margins'
  }
  if (!padding) {
    padding = 'padded'
  }
  if (!layout) {
    layout = '50/50'
  }
  if (!verticalAlignment) {
    verticalAlignment = 'center'
  }
  if (!columnOrder) {
    columnOrder = 'leftToRight'
  }

  let fullWidth = false
  if (width === 'fullWidth') {
    fullWidth = true
  }

  // Flip layout to keep consistent when order is rtl
  if (columnOrder === 'rightToLeft') {
    if (layout.includes('/')) {
      const firstSide = layout.split('/')[1]
      const secondSide = layout.split('/')[0]
      layout = firstSide + '/' + secondSide
    }
  }

  let padded = true
  if (padding === 'notPadded') {
    padded = false
  }

  return (
    <FFSection
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      padded={padded}
      isFirstSection={isFirstSection}
    >
      <Grid small={fullWidth ? '[1]' : '1 [12] 1'}>
        <Grid
          small='[1]'
          medium={gridSetup(layout, gutterSetup[gutters]).medium}
          large={gridSetup(layout, gutterSetup[gutters]).large}
          larger={gridSetup(layout, gutterSetup[gutters]).larger}
          rowGap={['5vw', '5vw', '80px']}
          vAlign={verticalAlignment}
          gridDirection={gridDirection[columnOrder]}
          // showOverlay={true}
        >
          {columns.map((column, index) => {
            return (
              <ColumnWrapper
                key={column.id}
                isMedia={!column.content}
                vPadded={column.content && fullWidth}
                hPadded={column.content && fullWidth}
                sectionPadded={padded}
                gutters={gutterSetup[gutters]}
                index={index}
                gridDirection={gridDirection[columnOrder]}
              >
                {column.content ? (
                  <Column
                    delay={index}
                    items={column.content}
                    type={column.type}
                    columnTheme={theme}
                  />
                ) : (
                  false
                )}

                {column.__typename === 'ContentfulVideo' && (
                  <ScrollEntrance delay={index}>
                    <Video
                      video={column.video}
                      playing={true}
                      loop={true}
                      posterImage={column.posterImage}
                      autoplay={true}
                      playButton={column.playButton}
                    />
                  </ScrollEntrance>
                )}

                {column.__typename === 'ContentfulText' && (
                  <TextLockup
                    text={column.text}
                    eyebrow={column.eyebrow}
                    textSize={column.textSize}
                    entranceDelay={index}
                  />
                )}

                {column.__typename === 'ContentfulImage' && (
                  <ScrollEntrance delay={index + 1}>
                    <Image
                      image={column.image}
                      medium={column.medium}
                      small={column.small}
                      link={column.link}
                      linkedFile={column.linkedFile}
                      sizes={
                        '(max-width: ' + mq.smallBreakpoint + 'px) 100vw, 50vw'
                      }
                      loading={isFirstSection ? 'eager' : 'lazy'}
                    />
                  </ScrollEntrance>
                )}
              </ColumnWrapper>
            )
          })}
        </Grid>
      </Grid>
    </FFSection>
  )
}

export default FiftyFifty
