import React from 'react'
import styled from '@emotion/styled'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import Img from 'gatsby-image'
import BlockQuote from 'src/components/BlockQuote'

const RichTextWrapper = styled.div`
	white-space: pre-wrap;
	* {
		white-space: pre-wrap;
	}
`

const InlineItem = styled.div`
	display: block;
	max-width: ${ ({ embedMaxWidth }) => embedMaxWidth };
	${ ({ first }) => first ? `
		margin-top: 0;
	` : `
		margin-top: 24px;
	` }
	${ ({ last }) => last ? `
		margin-bottom: 0;
	` : `
		margin-bottom: 24px;
	` }
`

const adjustSpace = (first, last) => {
	let style = {}
	if (first) {
		style.marginTop = 0
	}
	if (last) {
		style.marginBottom = 0
	}
	return style
}

const options = (linkInNewTab, embedMaxWidth) => {
	const textOptions = {
		renderMark: {
			[MARKS.BOLD]: text => <b>{text}</b>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<p style={adjustSpace(node.first, node.last)}>{children}</p>
			),
			[BLOCKS.HEADING_1]: (node, children) => (
				<h1 style={adjustSpace(node.first, node.last)}>{children}</h1>
			),
			[BLOCKS.HEADING_2]: (node, children) => (
				<h2 style={adjustSpace(node.first, node.last)}>{children}</h2>
			),
			[BLOCKS.HEADING_3]: (node, children) => (
				<h3 style={adjustSpace(node.first, node.last)}>{children}</h3>
			),
			[BLOCKS.HEADING_4]: (node, children) => (
				<h4 style={adjustSpace(node.first, node.last)}>{children}</h4>
			),
			[BLOCKS.HEADING_5]: (node, children) => (
				<h5 style={adjustSpace(node.first, node.last)}>{children}</h5>
			),
			[BLOCKS.HEADING_6]: (node, children) => (
				<h6 style={adjustSpace(node.first, node.last)}>{children}</h6>
			),
			[BLOCKS.UL_LIST]: (node, children) => (
				<ul style={adjustSpace(node.first, node.last)}>{children}</ul>
			),
			[BLOCKS.OL_LIST]: (node, children) => (
				<ol style={adjustSpace(node.first, node.last)}>{children}</ol>
			),
			[BLOCKS.LIST_ITEM]: (node, children) => (
				<li style={adjustSpace(node.first, node.last)}>{children}</li>
			),
			[BLOCKS.QUOTE]: (node, children) => (
				<InlineItem style={adjustSpace(node.first, node.last)}>
					<BlockQuote style={adjustSpace(node.first, node.last)} quote={children}/>
				</InlineItem>
			),
			[BLOCKS.HR]: (node, children) => (
				<hr style={adjustSpace(node.first, node.last)}/>
			),
			[BLOCKS.EMBEDDED_ENTRY]: (node, children) => (
				<div style={adjustSpace(node.first, node.last)}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node, children) => {
				console.log(node, children)
				if (node?.data?.target?.fields?.file['en-US']?.contentType.includes('image')) {
					const image = node?.data?.target?.fields?.file['en-US']
					let maxWidthNum = 1200
					if (embedMaxWidth && !embedMaxWidth.includes('%')) {
						maxWidthNum = embedMaxWidth.replace(/\D/g,'')
					}
					return <InlineItem style={adjustSpace(node.first, node.last)} embedMaxWidth={embedMaxWidth || '100%'}>
						<Img fluid={{
							aspectRatio: image.details.image.width / image.details.image.height,
							src: 'https:' + image.url + '?w=' + maxWidthNum * 2,
							sizes: embedMaxWidth
						}}/>
					</InlineItem>
				} else {
					return children
				}
			},
			[INLINES.HYPERLINK]: (node, children) => (
				<a href={node.data.uri} target={linkInNewTab ? '_blank' : ''}>{children}</a>
			)
		},
	}
	return textOptions
}

const ContentfulRichText = ({ richText, className, linkInNewTab, embedMaxWidth = '100%' }) => {
	
	if (!richText) {
		return false
	}

	const text = richText.content

	// let renderer know which is the first item
	if (text[0]) {
		text[0].first = true
	}

	// if (text[text.length - 1].content[0].value === '' && text[text.length - 1].content.length === 1) {
	// 	// remove last item if empty
	// 	text.pop()
	// 	// let renderer know which is the last item
	// 	if (text[richText.content.length - 1]) {
	// 		text[richText.content.length - 1].last = true
	// 	}
	// } else {
	// 	// let renderer know which is the last item
	// 	if (text[richText.content.length - 1]) {
	// 		text[richText.content.length - 1].last = true
	// 	}
	// }

	return (
		<RichTextWrapper className={className}>
			{documentToReactComponents(richText, options(linkInNewTab, embedMaxWidth))}
		</RichTextWrapper>
	)
}

// ContentfulRichText.defaultProps = {
// 	richText: richText
// }

export default ContentfulRichText
