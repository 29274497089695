import React from "react"
import styled from "@emotion/styled"
import Section from "src/components/Section"
import TextLockup from "src/components/TextLockup"
import Grid from "src/components/Grid"

const Wrapper = styled(Section)`
  ${({ alignment }) =>
    alignment !== "right" &&
    `
    text-align: ${alignment};
  `}
  h1, h2, h3, h4, h5, h6, p {
    ${({ alignment }) =>
      alignment === "center" &&
      `
      margin-left: auto;
      margin-right: auto;
    `}
  }
  h1,
  h2,
  h3 {
    max-width: 20em;
  }
  h4,
  h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 36em;
  }
`

const TextSection = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  eyebrow,
  sectionText,
  paragraphSize,
  actions,
  alignment,
  isFirstSection,
  id,
}) => {
  if (!sectionText && !eyebrow && !actions) {
    return false
  }
  const align = {
    // same as in WideMedia.jsx
    left: {
      medium: "container",
      large: "container",
      larger: "container",
    },
    center: {
      medium: "container",
      large: "container",
      larger: "container",
    },
    right: {
      medium: "7 [6] 1",
      large: "7 [6] 1",
      larger: "7 [5] 2",
    },
  }

  if (!alignment || alignment === null) {
    alignment = "left"
  }

  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      alignment={alignment}
      isFirstSection={isFirstSection}
    >
      <Grid
        small="1 [12] 1"
        medium={align[alignment].medium}
        large={align[alignment].large}
        larger={align[alignment].larger}
      >
        <TextLockup
          eyebrow={eyebrow}
          text={sectionText}
          textSize={paragraphSize}
          buttons={actions}
          theme={theme}
        />
      </Grid>
    </Wrapper>
  )
}

export default TextSection
