import React from "react"
import styled from "@emotion/styled"
import Section from "src/components/Section"
import Grid from "src/components/Grid"
import Img from "gatsby-image"
import Video from "src/components/Video"
import TextLockup from "src/components/TextLockup"
import ScrollEntrance from "src/components/ScrollEntrance"
import { mq } from "src/styles"

const FFSection = styled(Section)``

const ColumnWrapper = styled.div`
  h1,
  h2,
  h3 {
    max-width: 20em;
  }
  h4,
  h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 40em;
  }
`

const arrangeMedia = {
  left: {
    small: "2 [10] 2 [12] 2",
    normal: "2 [11] 2 [11] 2",
    large: "2 [12] 2 [10] 2",
    extraLarge: "2 [13] 2 [9] 2",
  },
  right: {
    small: "2 [10] 2 [12] 2",
    normal: "2 [11] 2 [11] 2",
    large: "2 [12] 2 [10] 2",
    extraLarge: "2 [13] 2 [9] 2",
  },
  bleedLeft: {
    small: "[10] 2 [14] 2",
    normal: "[13] 2 [11] 2",
    large: "[14] 2 [10] 2",
    extraLarge: "[15] 2 [9] 2",
  },
  bleedRight: {
    small: "[10] 2 [14] 2",
    normal: "[13] 2 [11] 2",
    large: "[14] 2 [10] 2",
    extraLarge: "[15] 2 [9] 2",
  },
}

const mediaSizes = {
  noBleed: {
    normal: (100 / 28) * 11,
    large: (100 / 28) * 12,
    extraLarge: (100 / 28) * 13,
  },
  bleed: {
    normal: (100 / 28) * 13,
    large: (100 / 28) * 14,
    extraLarge: (100 / 28) * 15,
  },
}

const FiftyFiftyV2 = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  ffMedia,
  mediaPlacement,
  mediaWidth,
  eyebrow,
  ffText,
  actions,
  paragraphSize,
  verticalAlignment,
  isFirstSection,
  component,
  embeddedImageWidth,
  playButton
}) => {
  if (!mediaWidth || mediaWidth === null) {
    mediaWidth = "normal"
  }
  if (!mediaPlacement || mediaPlacement === null) {
    mediaPlacement = "left"
  }

  // set responsive image sizes
  let sizes = "100vw"
  let imageSize = mediaSizes.noBleed[mediaWidth]
  if (mediaPlacement.includes("bleed")) {
    imageSize = mediaSizes.bleed[mediaWidth]
  }

  let media = ffMedia
  let text = ffText

  if (media?.fluid?.sizes) {
    media.fluid.sizes =
      "(min-width: " + mq.mediumBreakpoint + "px) " + imageSize + "vw, 86vw"
  }

  console.log('FF v2:::: media::', media)

  return (
    <FFSection
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
    >
      <Grid
        small="1 [12] 1"
        large={arrangeMedia[mediaPlacement || "left"][mediaWidth || "normal"]}
        rowGap={["7vw", "7vw", "80px"]}
        vAlign={verticalAlignment || "center"}
        gridDirection={
          mediaPlacement.includes("right") || mediaPlacement.includes("Right")
            ? "rtl"
            : "ltr"
        }
      >
        <ColumnWrapper>
          {component ? (
            <ScrollEntrance>
              <div>non-media-component</div>
            </ScrollEntrance>
          ) : (
            <>
              {media && media.file.contentType === "video/mp4" && (
                <ScrollEntrance>
                  <Video video={media} playButton={playButton}/>
                </ScrollEntrance>
              )}
              {media && media.file.contentType.includes("image") && (
                <ScrollEntrance>
                  <Img
                    fluid={media.fluid}
                    loading={isFirstSection ? "eager" : "lazy"}
                    alt={media.file.fileName}
                    placeholder="none"
                  />
                </ScrollEntrance>
              )}
            </>
          )}
        </ColumnWrapper>

        <ColumnWrapper>
          <TextLockup
            entranceDelay={1}
            eyebrow={eyebrow}
            text={text}
            textSize={paragraphSize}
            buttons={actions}
            theme={theme}
            embedMaxWidth={embeddedImageWidth}
          />
        </ColumnWrapper>
      </Grid>
    </FFSection>
  )
}

FiftyFiftyV2.defaultProps = {
  mediaWidth: "normal",
  mediaPlacement: "left",
}

export default FiftyFiftyV2
