import React from 'react'
import styled from '@emotion/styled'
import Input from 'src/components/Input'
import Button from 'src/components/Button'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { colors, typography } from 'src/styles'

const Wrapper = styled.div`
	text-align: center;
	background: ${ colors.lightGrey };
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 30px;
`

const ErrorMessage = styled.p`
	${ typography.bodySmall }
	color: ${ colors.red };
	font-weight: 600;
	margin: 0;
`

const PwProtectionLogin = ({ className, unlockPage, handleInput, enteredPassword, error }) => (
  <Wrapper className={className}>
  	<Grid small="1 [12] 1" medium="2 [10] 2" large="4 [6] 4" larger="5 [4] 5" rowGap="30px">
	  	<ScrollEntrance delay="14">
		  	<h3 style={{ marginBottom: 0 }}>Please enter password <br/>to view this page.</h3>
	  	</ScrollEntrance>
	  	<ScrollEntrance delay="15">
				<Input
					placeholder="Password"
					onChange={handleInput}
					name="enteredPassword"
					type="password"
					value={enteredPassword}
					setTheme="lightGrey"
				/>
			</ScrollEntrance>
			{error && (
				<div>
					<ErrorMessage>The password you have entered is incorrect.</ErrorMessage>
				</div>
			)}
			<ScrollEntrance delay="16">
				<div>
					<Button onClick={unlockPage}>Unlock page</Button>
				</div>
			</ScrollEntrance>
		</Grid>
  </Wrapper>
)

export default PwProtectionLogin
