import React from 'react'
import styled from '@emotion/styled'
import TextLockup from 'src/components/TextLockup'
import Link from 'src/components/Link'
import Image from 'src/components/Image'
import Video from 'src/components/Video'
import List from 'src/components/List'
import BlockQuote from 'src/components/BlockQuote'
import ImageWithText from 'src/components/ImageWithText'
import Button from 'src/components/Button'
import Embed from 'src/components/Embed'
import Card from 'src/components/Card'
import Slideshow from 'src/components/Slideshow'
import ComplexSlideshow from 'src/components/ComplexSlideshow'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ContactForm from 'src/components/ContactForm'
import { mq, util, colors } from 'src/styles'

const componentMap = {
  ContentfulText: TextLockup,
  ContentfulEmbed: Embed,
  ContentfulContactForm: ContactForm,
  ContentfulSlideshow: {
    default: Slideshow,
    bleedOffSlideshow: ComplexSlideshow,
    numberedBleedOffSlideshow: ComplexSlideshow,
  },
  ContentfulLink: {
    default: Link,
    textLink: Link,
    button: Button,
  },
  ContentfulImage: Image,
  ContentfulVideo: Video,
  ContentfulList: {
    default: List,
    horizontal: List,
    vertical: List,
    checkList: List,
  },
  ContentfulBlockQuote: BlockQuote,
  ContentfulImageWithText: ImageWithText,
}

const ColumnContent = styled.div`
	> div {
	  &:not(:first-of-type) {
	  	${util.responsiveStyles("margin-top", 30, 24, 20, 16)}
	  }
  }
  ${({ type }) =>
    type.startsWith("card")
      ? `
  	${util.responsiveStyles("padding", 60, 50, 40, 16)}
  	height: 100%;
  `
      : ``}

  ${({ type }) =>
    type === "card CTA"
      ? `
  	h1, h2, h3, h4 {
  		margin: 0;
  	}
  	h3 {
  		color: ${colors.blue};
  	}
  `
      : ``}

  ${({ type }) =>
    type === "bio"
      ? `
		h3 {
			${mq.mediumAndUp} {
				margin-top: -.2em !important;
			}
		}
  	h6 {
  		color: ${colors.blue};
  	}
  	p {
  		max-width: 44em;
  	}
  `
      : ``}
`

const RenderContent = ({ items, delay, theme }) => {
  return items.map((item, index) => {
    let Component = componentMap[item.__typename]
    if (item.type || item.type === null) {
      Component = componentMap[item.__typename][item.type]
      if (!Component) {
        Component = componentMap[item.__typename].default
      }
    }
    return Component ? (
      <ScrollEntrance
        key={item.id}
        delay={index + delay}
        transitionIn={item.__typename !== "ContentfulText"}
      >
        <div>
          <Component
            {...item}
            fade={true}
            dots={false}
            controls={false}
            playing={true}
            autoplay={true}
            loop={true}
            pauseOnHover={false}
            autoplaySpeed={7000}
            speed={1300}
            delay={index}
            entranceDelay={index}
            theme={theme}
            componentTheme={item.theme || "default"}
          />
        </div>
      </ScrollEntrance>
    ) : (
      false
    )
  })
}

const Column = ({ items, delay, type, columnTheme }) => {
  if (!items) {
    return false
  }

  if (type === null) {
    type = "default"
  }

  return (
    <ColumnContent as={type.startsWith("card") ? Card : "div"} type={type}>
      <RenderContent items={items} delay={delay} theme={columnTheme} />
    </ColumnContent>
  )
}

Column.defaultProps = {
  type: "default",
}

export default Column
